/**
 * hide scrollbars
 */
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.link-disable {
  opacity: 0.6;
  cursor: default;
}

#root {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

body {
  @apply font-sans;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-muted {
  color: #b5b5c3 !important;
}

.icon-button {
  @apply inline-flex items-center px-2 py-2 border-solid border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

/* body {
  --diagram_font_size: 500px;
} */

.link {
  @apply text-blue-500;
}

.ring-red {
  box-shadow: 0 0 0 3px theme('colors.red.300');
}
.ring-green {
  box-shadow: 0 0 0 3px theme('colors.green.300');
}
.ring-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}
.ring-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.bg-1 {
  background: url('~bg-login-2.png') no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-2 {
  /* background: url(/images/bg-login-2.png) no-repeat 0 0 fixed; */
  background-size: cover;
}
.bg-login-3 {
  background: url('~bg-login-2.png') no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(theme('spacing.1') * 96);
}
.w-128 {
  width: calc(theme('spacing.1') * 128);
}

/* social media colors */
.text-facebook {
  color: #365397;
}
.text-twitter {
  color: #00a9f1;
}
.text-github {
  color: #2f2f2f;
}

.text-linkedin {
  color: #006db3;
}
.text-apple {
  color: #737373;
}
.text-google {
  color: #4285f4;
}
.text-google-plus {
  color: #e0452c;
}
.text-youtube {
  color: #ff3333;
}
.text-vimeo {
  color: #48b6ed;
}
.text-pinterest {
  color: #ce1a19;
}
.text-yelp {
  color: #c30f00;
}
.text-dribbble {
  color: #ed4584;
}
.text-amazon {
  color: #ff9700;
}
.text-skype {
  color: #00acf4;
}
.text-instagram {
  color: #396d9a;
}
.text-dropbox {
  color: #0d84de;
}
.text-flickr {
  color: #ea0066;
}
.text-tumblr {
  color: #304c68;
}
.text-foursquare {
  color: #207dc5;
}
