.form-element {
  &:not(.form-element-inline) {
    @apply flex flex-col mb-4;
    .form-label {
      @apply mb-2 text-sm;
    }
    .form-hint,
    .form-error,
    .form-success {
      @apply mt-2;
    }
  }
  &.form-element-inline {
    @apply flex flex-row items-center justify-start;
    .form-label {
      @apply text-sm whitespace-nowrap;
    }
    > * {
      @apply mr-2 text-sm;
    }
  }
  &.flush--bottom {
    @apply mb-0;
  }
  .form-input,
  .form-textarea {
    @apply text-sm block;
    &:focus {
      @apply border border-solid border-blue-M900 outline-none shadow-none;
    }
    &.form-input-valid {
      @apply border border-green-500;
    }
    &.form-input-invalid {
      @apply border border-red-500;
    }
    [data-background='dark'] & {
      @apply bg-grey-800 border-grey-700 text-white;
      &::placeholder {
        color: theme('colors.white');
      }
    }
  }
  .form-select {
    @apply text-sm block w-full;
    &.form-select-valid {
      @apply border border-green-500;
    }
    &.form-select-invalid {
      @apply border border-red-500;
    }
    [data-background='dark'] & {
      @apply bg-grey-800 border-grey-700 text-white;
    }
  }
  .form-radio {
    @apply text-sm;
    &.form-radio-valid {
      @apply text-green-500 text-sm;
      + span {
        @apply text-green-500 text-sm;
      }
    }
    &.form-radio-invalid {
      @apply text-red-500 text-sm;
      + span {
        @apply text-red-500 text-sm;
      }
    }
  }
  .form-hint {
    @apply text-xs text-grey-500 whitespace-nowrap;
  }
  .form-error {
    @apply text-xs text-red-500 whitespace-nowrap;
  }
  .form-success {
    @apply text-xs text-green-500 whitespace-nowrap;
  }
}
.form-icon {
  @apply h-4 w-4 stroke-current absolute bottom-0;
  margin-bottom: 10px;
  &.form-success {
    @apply text-red-500;
  }
  &.form-error {
    @apply text-green-500;
  }
  @apply right-0 mr-2;
}

.mbt-auto {
  margin-top: auto;
}

.w-94 {
  width: 26rem;
}

.min-w-26 {
  min-width: 26rem;
}

.flush--bottom {
  .form-element {
    @apply mb-0;
  }
}

.dynamic-action--padding + span {
  /* span { */
  @apply mt-6;
  /* } */
}

.dynamic-action--padding + button {
  /* span { */
  margin-top: 1.8rem;
  /* } */
}

.dynamic-action-button--padding {
  /* span { */
  button {
    margin-top: 1.8rem;
  }
  /* } */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  /* margin: 0;  */
}

.form-label.required:after {
  color: red;
  content: ' *';
}

input[type='text'],
input[type='number'],
textarea {
  @apply rounded-xlg;
}

textarea.form-textarea {
  @apply rounded;
}

input[type='checkbox'] {
  @apply rounded border-grey-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}

input[type='radio'] {
  @apply border-grey-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}

input:disabled {
  @apply cursor-not-allowed;
}

.theme-multi-select {
  input[type='text'] {
    @apply focus:ring-0;
  }
}
