.table {
  @apply w-full table-auto;
  &.no-border thead tr th,
  &.no-border tbody tr td {
    @apply border-0 border-none;
  }
  thead tr th {
    @apply px-3 py-2 border-b text-xs leading-4 font-medium uppercase tracking-wider;
  }
  tbody tr td {
    @apply px-3 py-2 border-b;
  }
  &.table-sm {
    thead tr th {
      @apply px-1 py-0;
    }
    tbody tr td {
      @apply px-1 py-0;
    }
  }
  &.table-lg {
    thead tr th {
      @apply px-6 py-3;
    }
    tbody tr td {
      @apply px-6 py-3;
    }
  }
}

/* .table {
  @apply text-left;
  thead tr th,
  tbody tr td {
    @apply text-left;
  }
} */

[data-background='light'] {
  .table {
    thead tr th {
      @apply border-grey-200;
      @apply text-grey-500;
    }
    tbody tr td {
      border-color: color(#fff shade(6%));
    }
    &.striped {
      tbody tr:nth-child(odd) td {
        @apply bg-white;
      }
      tbody tr:nth-child(even) td {
        background: color(#fff shade(3%));
      }
    }
  }
}

[data-background='dark'] {
  .table {
    thead tr th {
      border-color: color(theme('colors.grey.900') tint(10%));
      @apply text-grey-500;
    }
    tbody tr td {
      border-color: color(theme('colors.grey.900') tint(10%));
    }
    &.striped {
      thead tr th {
        background: color(theme('colors.grey.900') tint(6%));
        border-color: color(theme('colors.grey.900') tint(6%));
      }
      tbody tr:nth-child(odd) td {
        background: color(theme('colors.grey.900') tint(6%));
        border-color: color(theme('colors.grey.900') tint(6%));
      }
      tbody tr:nth-child(even) td {
        background: color(theme('colors.grey.900') tint(3%));
        border-color: color(theme('colors.grey.900') tint(3%));
      }
    }
  }
}

.table-gs {
  @apply w-full;
  &.no-border thead tr th,
  &.no-border tbody tr td {
    @apply border-0 border-none;
  }
  td.no-padding {
    @apply p-0;
  }
  thead tr th {
    @apply px-2 py-2 border text-xs leading-4 font-medium tracking-wider border-grey-900;
  }
  tbody tr td {
    @apply px-2 py-2 border font-normal border-grey-900;
  }
  tbody td {
    vertical-align: top;
  }
}

.table-cl {
  @apply w-full;
  &.no-border thead tr th,
  &.no-border tbody tr td {
    @apply border-0 border-none;
  }
  td.no-padding {
    @apply p-0;
  }
  thead tr th {
    @apply px-2 py-2 border text-xs leading-4 font-medium tracking-wider border-grey-900;
  }
  tbody tr td {
    @apply px-2 py-2 border font-normal border-grey-900;
  }
  tbody td {
    vertical-align: top;
  }
}

@media print {
  .table-gs,
  .table-cl,
  .table-gs tr td,
  .table-gs tr th,
  .table-cl tr td,
  .table-cl tr th {
    page-break-inside: avoid;
  }
  /* div {
    page-break-inside: avoid;
  } */
  .table-gs {
    thead tr th:first-child {
      @apply border-l-0;
    }
    thead tr th:last-child {
      @apply border-r-0;
    }
    tbody tr td:first-child {
      @apply border-l-0;
    }
    tbody tr td:last-child {
      @apply border-r-0;
    }
  }

  .table-cl {
    thead tr th {
      @apply font-bold;
    }

    thead tr th {
      @apply px-1 py-1 border text-xs leading-4 font-medium tracking-wider border-grey-900;
    }
    tbody tr td {
      @apply px-1 py-1 border font-normal border-grey-900;
    }
  }

  /* .table-print-font-small {
    font-size: 0.65em !important;
  } */

  .table-print-font-small th {
    font-size: 0.5rem !important;
  }

  .table-print-font-small div {
    font-size: 0.5rem !important;
  }

  .table-print-font-small td {
    font-size: 0.5rem !important;
  }
}

.table-quote {
  @apply w-full table-fixed;
  &.no-border thead tr th,
  &.no-border tbody tr td {
    @apply border-0 border-none;
  }
  thead tr th {
    @apply px-0 py-2 border-b text-xs leading-4 font-medium uppercase tracking-wider;
  }
  tbody tr td {
    @apply px-0 py-2 border-b;
  }
  tbody td {
    vertical-align: top;
  }
  &.table-sm {
    thead tr th {
      @apply px-1 py-0;
    }
    tbody tr td {
      @apply px-1 py-0;
    }
  }
  &.table-lg {
    thead tr th {
      @apply px-6 py-3;
    }
    tbody tr td {
      @apply px-6 py-3;
    }
  }

  tbody img svg text {
    font-size: 200px;
  }
}

/* .table {
  @apply text-left;
  thead tr th,
  tbody tr td {
    @apply text-left;
  }
} */

[data-background='light'] {
  .table-quote {
    thead tr th {
      @apply border-grey-200;
      @apply text-grey-500;
    }
    tbody tr td {
      border-color: color(#fff shade(6%));
    }
    &.striped {
      tbody tr:nth-child(odd) td {
        @apply bg-white;
      }
      tbody tr:nth-child(even) td {
        background: color(#fff shade(3%));
      }
    }
  }
}

[data-background='dark'] {
  .table-quote {
    thead tr th {
      border-color: color(theme('colors.grey.900') tint(10%));
      @apply text-grey-500;
    }
    tbody tr td {
      border-color: color(theme('colors.grey.900') tint(10%));
    }
    &.striped {
      thead tr th {
        background: color(theme('colors.grey.900') tint(6%));
        border-color: color(theme('colors.grey.900') tint(6%));
      }
      tbody tr:nth-child(odd) td {
        background: color(theme('colors.grey.900') tint(6%));
        border-color: color(theme('colors.grey.900') tint(6%));
      }
      tbody tr:nth-child(even) td {
        background: color(theme('colors.grey.900') tint(3%));
        border-color: color(theme('colors.grey.900') tint(3%));
      }
    }
  }
}
